<template>
  <div>
    <b-card title="" header-tag="header" class="card-custom">
      <template v-slot:header>
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ title }}</h3>
        </div>
        <div class="card-toolbar"></div>
      </template>

      <form class="form" novalidate="novalidate" id="product_basic">
        <div class="d-flex align-items-center mb-10">
          <div class="symbol symbol-40 mr-5 symbol-light-primary">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-lg svg-icon-primary">
                <i class="las la-braille"></i> </span
            ></span>
          </div>
          <div class="d-flex flex-column font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg"> General </a>
            <!-- <span class="text-muted">Sub heading</span> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left">Banner Image</label>
          <div class="col-lg-9 col-xl-9">
            <div class="image-input image-input-outline" id="kt_user_add_avatar">
              <div
                class="image-input-wrapper"
                :style="{
                  backgroundImage: `url(${
                    previewImage != null ? previewImage : imgPath + form.image
                  })`,
                }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  ref="file"
                  @change="selectImage"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title=""
                data-original-title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Banner Image Mobile</label
          >
          <div class="col-lg-9 col-xl-9">
            <div class="image-input image-input-outline" id="kt_user_add_avatar">
              <div
                class="image-input-wrapper"
                :style="{
                  backgroundImage: `url(${
                    previewMobileImage != null
                      ? previewMobileImage
                      : imgPath + form.image_mob
                  })`,
                }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  ref="mobileImage"
                  @change="selectMobileImage"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title=""
                data-original-title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
          </div>
        </div>
        <b-form-group label-for="input-1">
          <label
            >Category Name <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-input
            v-model="form.name"
            type="text"
            name="name"
            class="form-control"
            placeholder="EG: Shape"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label-for="input-1">
          <label>Banner Class</label>
          <b-form-input
            v-model="form.banner_class"
            type="text"
            name="banner_class"
            class="form-control"
            placeholder="EG: Shape"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label-for="input-1">
          <label
            >Category Slug <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-input
            v-model="form.page_key"
            type="text"
            name="slug"
            class="form-control"
            placeholder="EG: Shape"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label-for="input-1">
          <label>Description <sup class="text-danger font-weight-boldest">*</sup></label>
          <Editor
            v-model="form.description"
            name="description"
            editorStyle="height: 320px"
          />
        </b-form-group>
        <b-form-group label-for="input-1">
          <label
            >Meta Tag Title <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-input
            v-model="form.meta_title"
            type="text"
            name="meta-title"
            class="form-control"
            placeholder="EG: Shape"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label-for="input-1">
          <label
            >Meta Tag Description
            <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-textarea
            v-model="form.meta_description"
            name="meta-description"
            class="form-control"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group label-for="input-1">
          <label
            >Meta Tag Keyword <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-textarea
            v-model="form.meta_keyword"
            name="meta-keyword"
            class="form-control"
          ></b-form-textarea>
        </b-form-group>
        <div class="d-flex align-items-center mb-10">
          <div class="symbol symbol-40 mr-5 symbol-light-primary">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-lg svg-icon-primary">
                <i class="las la-server"></i> </span
            ></span>
          </div>
          <div class="d-flex flex-column font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg"> Data</a>
            <!-- <span class="text-muted">Sub heading</span> -->
          </div>
        </div>
        <b-row>
          <b-col md="4">
            <b-form-group label="Parent">
              <b-form-select
                v-model="form.parent_id"
                name="type"
                :options="parentCategoryList"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label-for="input-1">
          <label
            >Filter Groups <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <treeselect
            v-model="form.filters"
            :max-height="200"
            :default-expand-level="1"
            :disable-branch-nodes="true"
            :multiple="true"
            :options="filters"
          />
          <!-- <vue-select :options="filters" :multiple="true" :loading="loading" v-model="form.filter_groups" label="name" placeholder="Select filters">
                    <template v-slot:option="option">
                        <div class="d-flex align-items-center">
                            <h6 class="flex-fixed mb-0 mt-1 text-hint">{{ option.name }}</h6>
                        </div>
                    </template>
                    <template v-slot:selected-option="option" :reduce="(option) => option.id">
                        <div>
                            <div class="d-flex align-items-center">
                                <h6 class="flex-fixed mb-0 mt-1 text-hint">{{ option.name }}</h6>
                            </div>
                        </div>
                    </template>
                </vue-select> -->
        </b-form-group>
        <b-row>
          <b-col md="4">
            <b-form-group label-for="input-1">
              <label>Top <sup class="text-danger font-weight-boldest">*</sup></label>
              <b-form-select v-model="form.top">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label-for="input-1">
              <label
                >Sort Order <sup class="text-danger font-weight-boldest">*</sup></label
              >
              <b-form-input
                v-model="form.sort_order"
                type="number"
                name="description"
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label-for="input-1">
              <label>Status <sup class="text-danger font-weight-boldest">*</sup></label>
              <b-form-select v-model="form.status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex align-items-center mb-10">
          <div class="symbol symbol-40 mr-5 symbol-light-primary">
            <span class="symbol-label"
              ><span class="svg-icon svg-icon-lg svg-icon-primary">
                <i class="las la-hashtag"></i> </span
            ></span>
          </div>
          <div class="d-flex flex-column font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg">SEO</a>
            <!-- <span class="text-muted">Meta tags and keyword</span> -->
          </div>
        </div>
        <b-row>
          <b-col md="12">
            <b-form-group label-for="input-1">
              <label>Seo Title</label>
              <b-form-input
                v-model="form.seo_title"
                type="text"
                name="seo-title"
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group class="fw-600">
              <label>Seo Content</label>
              <Editor
            v-model="form.seo_content"
            name="description"
            editorStyle="height: 320px"
          />
              <!-- <b-form-textarea
                v-model="form.seo_content"
                placeholder=""
                rows="3"
                max-rows="6"
              >
              </b-form-textarea> -->
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <button ref="kt_login_signin_submit" class="btn btn-primary">
              <i class="las la-save"></i> Save
            </button>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Editor from "primevue/editor";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import CategoryService from "@/core/services/api/category";
import FilterService from "@/core/services/api/filter";
import Swal from "sweetalert2";
export default {
  components: { Editor },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      currentImage: null,
      currentMobileImage: null,
      previewImage: null,
      previewMobileImage: null,
      title: "New Category",
      parentCategoryList: [{ value: 0, text: "Select parent" }],
      filters: [],
      loading: false,
      selection: null,
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      form: {
        id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
        top: 0,
        name: "",
        page_key: "",
        image: "",
        image_mob: "",
        banner_class: "",
        sort_order: 0,
        description: "",
        meta_title: "",
        meta_description: "",
        meta_keyword: "",
        parent_id: 0,
        parentInfo: [],
        filters: [],
        status: "active",
        seo_content: "",
        seo_title: "",
      },
    };
  },
  mounted() {
    var vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Category",
        route: "/category",
      },
      {
        title: "Category : New",
      },
    ]);
    const signin_form = KTUtil.getById("product_basic");
    this.fv = formValidation(signin_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required",
            },
          },
        },
        slug: {
          validators: {
            notEmpty: {
              message: "Slug is required",
            },
          },
        },
        group: {
          validators: {
            notEmpty: {
              message: "Attribute group is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", async () => {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");

      // var data = {
      //     name: vm.form.name,
      //     page_key: vm.form.page_key,
      //     parent_id: vm.form.parentInfo.length != 0 ?vm.form.parentInfo.id:0,
      //     description:vm.form.description,
      //     meta_title:vm.form.meta_title,
      //     meta_description:vm.form.meta_description,
      //     meta_keyword:vm.form.meta_keyword,
      //     top:vm.form.top,
      //     status:vm.form.status,
      //     sort_order:vm.form.sort_order,
      //     filters: vm.form.filters
      // }

      const formData = new FormData();
      formData.append("category", JSON.stringify(vm.form));
      formData.append("image", vm.currentImage);
      formData.append("image_mob", vm.currentMobileImage);
      if (this.id == null) {
        await this.createCategory(formData, function (response) {
          vm.response(response, submitButton);
        });
      } else {
        await this.updateCategory(formData, function (response) {
          vm.response(response, submitButton);
        });
      }
    });

    this.getCategories();
    this.getFilterGroups();
    if (this.id != null) {
      this.getCategoryById();
    }
  },
  methods: {
    response(response, submitButton) {
      var vm = this;
      if (response) {
        let timerInterval;
        Swal.fire({
          title: response.message,
          timer: 500,
          icon: "success",
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          vm.$router.push({ name: "category" });
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    },
    async getCategories() {
      var response = await CategoryService.getCategories();
      if (this.id != null) {
        var removeIndex = response.data
          .map(function (item) {
            return item.id;
          })
          .indexOf(this.id);
        response.data.splice(removeIndex, 1);
      }
      response.data.map((e) => {
        this.parentCategoryList.push({ value: e.id, text: e.name });
      });
    },
    async getFilterGroups() {
      var response = await FilterService.getFilterGroups();
      this.filters = response.data.map((e) => {
        return {
          id: e.id,
          label: e.name,
        };
      });
    },
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
    },
    selectMobileImage() {
      this.currentMobileImage = this.$refs.mobileImage.files.item(0);
      this.previewMobileImage = URL.createObjectURL(this.currentMobileImage);
    },
    async getCategoryById() {
      var response = await CategoryService.getCategoryById(this.id);
      this.form = response;
      this.form.filters = this.form.filter_groups.map((e) => {
        return e.id;
      });
    },
    createCategory(formData, callback) {
      CategoryService.createCategory(formData)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
    updateCategory(formData, callback) {
      var vm = this;
      CategoryService.updateCategory(formData, this.id)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
  },
};
</script>

<style></style>
